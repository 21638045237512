<template>
  <v-layout max-width class="reportConfigure flex-column">
    <div class="flex-y-center flex-justify-between white pa-2 mb-3 px-4" style="">
      <div>
        <!-- color="#00bea9" -->
        <v-btn dark small color="primary" title="新增报告" depressed min-width="30" height="30" style="padding:0 8px;" @click="addShow">
          <v-icon size="16">mdi-plus</v-icon>新增报告
        </v-btn>

      </div>
      <div class=" flex-y-center" />

    </div>
    <div class=" white pb-6">
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="search.pageSize"
        class="pb-5 max-width pa-4"
        hide-default-footer
        item-key="id"
        no-data-text="暂无查询数据"
        :loading="listLoading"
        disable-sort
      >
        <template v-slot:item.rowIndex="{index}">
          {{ (index+1)+(search.pageSize*(search.pageNo-1)) }}
        </template>
        <template v-slot:item.statusFlag="{item}">
          <span :class="item.statusFlag==1?'error--text':''">{{ item.statusFlag==1?'禁用':'可用' }}</span>
        </template>
        <template v-slot:item.active="{item}">
          <v-icon :disabled="item.statusFlag==='1'" color="primary" size="18" class=" mr-1" title="编辑" @click="showEdit(item)">mdi-square-edit-outline</v-icon>
          <v-icon color="primary" size="18" class=" mr-1" title="版本" @click="verList.reportId=item.id,verList.statusFlag=item.statusFlag,verList.show=true,initGetVersionList()">mdi-format-list-bulleted</v-icon>
          <!-- <v-icon color="primary" size="18" class=" mr-1" title="设计" @click="drawer.title=item.reportName,drawer.reportId=item.id,getForm('design')">mdi-view-dashboard-outline</v-icon> -->

          <!-- projectReportSetReportStatus -->
          <v-icon
            :color="item.statusFlag==1?'primary':'error'"
            size="18"
            class=" mr-1"
            :title="item.statusFlag==1?'恢复':'禁用'"
            @click="operation($api.projectReportSetReportStatus,'post',item.statusFlag==1?'恢复':'禁用',{id:item.id,statusFlag:item.statusFlag==1?'0':'1'})"
          >
            {{ item.statusFlag==1?'mdi-replay':'mdi-cancel' }}
          </v-icon>

          <v-icon
            color="error"
            size="20"
            class=" mr-1"
            title="删除"
            @click="operation($api.projectReportDelete,'delete','删除',{id:item.id})"
          >mdi-delete-outline</v-icon>

          <!-- <v-icon color="primary" size="18" class=" mr-1" title="预览" @click="drawer.title=item.reportName,drawer.reportId=item.id,getForm('see')">mdi-eye-outline</v-icon> -->
          <!-- <a class=" mr-2" @click="drawer.show=true">设计</a> -->

          <!-- <a class=" mr-2" @click="showEdit(item)">编辑</a>

          <a class=" mr-2 " @click="operation($api.projectReportDelete,'delete','删除',{id:item.id})">删除</a> -->
          <!-- <a class=" mr-2" @click="operation(item)">拷贝</a> mdi-checkbox-multiple-blank-outline-->
        </template>
      </v-data-table>
      <div class=" flex-justify-end">
        <pagination v-model="search.pageNo" :size="search.pageSize" :total="total" @input="getList" @changePageSize="changePageSize" />
      </div>
    </div>
    <!-- 新增 编辑 报告 -->
    <v-dialog v-model="dialog.show" width="600">
      <form @submit.prevent="submitAdd()">
        <v-card>
          <v-card-title class="font-weight-bold">{{ dialog.data.id?'编辑报告':'新增报告' }}</v-card-title>
          <v-divider />
          <v-card-text class=" py-5 body-1">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>报告名称：</div>
                <input
                  v-model="dialog.data.reportName"
                  class="flex-1 default-input py-1 subtitle-1"
                  placeholder="报告名称"
                  required
                  type="text"
                >
                <span class=" flex-0 flex-shrink-0 ml-1" style="width:20px;" />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">首次提交间隔上限：</div>
                <input
                  v-model="dialog.data.submitIntervalLimit"
                  class="flex-1 default-input py-1 subtitle-1"
                  placeholder="首次提交间隔上限"
                  type="text"
                  @input="dialog.data.submitIntervalLimit=$event.target.value?Number($event.target.value.replace(/\D+/,'')):null"
                >
                <span class=" flex-0 flex-shrink-0 ml-1 mt-2" style="width:20px;">天</span>
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">报告定稿间隔上限：</div>
                <input
                  v-model="dialog.data.tmfIntervalLimit"
                  class="flex-1 default-input py-1 subtitle-1"
                  placeholder="报告定稿间隔上限"
                  type="text"
                  @input="dialog.data.tmfIntervalLimit=$event.target.value?Number($event.target.value.replace(/\D+/,'')):null"
                >
                <span class=" flex-0 flex-shrink-0 ml-1 mt-2" style="width:20px;">天</span>
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">报告审阅间隔上限：</div>
                <!-- input 只能输入0或正整数 -->
                <input
                  v-model="dialog.data.auditIntervalLimit"
                  class="flex-1 default-input py-1 subtitle-1"
                  placeholder="报告审阅间隔上限"
                  type="text"
                  @input="dialog.data.auditIntervalLimit=$event.target.value?Number($event.target.value.replace(/\D+/,'')):null"
                >
                <span class=" flex-0 flex-shrink-0 ml-1 mt-2" style="width:20px;">天</span>
              </div>
              <!-- <div class="mb-6 flex-align-start">
                    <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>确认函间隔上限：</div>
                    <input class="flex-1 default-input py-1 subtitle-1" placeholder="确认函间隔上限" type="text"
                            v-model="dialog.data.comfirmIntervalLimit"/>
                    <span class=" flex-0 flex-shrink-0 ml-1 mt-2" style="width:20px;">天</span>
                </div>
                <div class="mb-6 flex-align-start">
                    <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>跟进函间隔上限：</div>
                    <input class="flex-1 default-input py-1 subtitle-1" placeholder="跟进函间隔上限" type="text"
                            v-model="dialog.data.followupIntervalLimit"/>
                    <span class=" flex-0 flex-shrink-0 ml-1 mt-2" style="width:20px;">天</span>
                </div> -->
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <v-btn width="80" height="30" class depressed @click="dialog.show = false,dialog.data={}">取消</v-btn>
                <v-btn
                  width="80"
                  type="submit"
                  :loading="btnLoading"
                  class="ml-3"
                  height="30"
                  color="primary"
                  depressed
                >确定</v-btn>

              </div>
            </div>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <!-- 新增 编辑 报告 end -->

    <!-- 版本编辑 新增 -->
    <v-dialog v-model="verEditDialog.show" width="600">
      <form @submit.prevent="submitVer()">
        <v-card>
          <v-card-title class="font-weight-bold">{{ verEditDialog.data.id?'编辑版本':'新增版本' }}</v-card-title>
          <v-divider />
          <v-card-text class=" py-5 body-1">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>版本名称：</div>
                <input
                  v-model="verEditDialog.data.versionName"
                  class="flex-1 default-input py-1 subtitle-1"
                  placeholder="版本名称"
                  required
                  type="text"
                >
                <span class=" flex-0 flex-shrink-0 ml-1" style="width:20px;" />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>版本日期：</div>
                <!-- <input
                  v-model="verEditDialog.data.versionDate"
                  class="flex-1 default-input py-1 subtitle-1"
                  placeholder="版本日期"
                  required
                  type="date"
                > -->
                <form-item
                  v-model="verEditDialog.data.versionDate"
                  class=" flex-1 flex-shrink-0"
                  placeholder="版本日期"
                  dense
                  allow-close
                  type="date"
                  @input="$forceUpdate()"
                />
                <span class=" flex-0 flex-shrink-0 ml-1" style="width:20px;" />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>质疑：</div>
                <form-item
                  v-model="verEditDialog.data.queryFlag"
                  class="flex-1"
                  :options="[
                    { text: '可质疑', value: '0' },
                    { text: '不可质疑', value: '1' },
                  ]"
                  placeholder="状态"
                  dense
                  type="select"
                  allow-close
                />
                <span class=" flex-0 flex-shrink-0 ml-1" style="width:20px;" />
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <v-btn width="80" height="30" class depressed @click="verEditDialog.show = false,verEditDialog.data={}">取消</v-btn>
                <v-btn
                  width="80"
                  type="submit"
                  height="30"
                  :loading="btnLoading"
                  class="ml-3"
                  color="primary"
                  depressed
                >确定</v-btn>

              </div>
            </div>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <!-- 版本编辑 新增 end -->

    <!-- 版本列表 -->
    <v-dialog v-model="verList.show" width="900" persistent no-click-animation>
      <v-card class=" flex-column overflow-hidden">
        <v-card-title class="font-weight-bold flex-justify-between">
          <span>版本列表</span>
          <v-icon
            class="v-icon notranslate v-chip__close v-icon--link v-icon--right mdi mdi-close theme--light"
            @click="verList.show = false,verList.data={}"
          >mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class=" flex-1 px-0 overflow-auto">
          <div class=" mb-3 max-width">
            <div class="flex-justify-between flex-y-center">
              <div class=" flex-y-center px-4 pt-4 pb-2">
                <v-btn dark small color="primary" title="新增版本" depressed min-width="30" height="30" style=" padding:0 8px;" @click="addVer">
                  <v-icon size="18">mdi-plus</v-icon>新增版本
                </v-btn>
              </div>
            </div>
            <div class=" flex-column flex-1  overflow-auto">
              <v-data-table
                :headers="verList.headers"
                :items="verList.list"
                :items-per-page="verList.search.pageSize"
                class="pb-5 max-width header-grey"
                hide-default-footer
                item-key="id"
                no-data-text="暂无查询数据"
                :loading="verList.listLoading"
                disable-sort
                dense
              >
                <template v-slot:item.rowIndex="{index}">
                  {{ (index+1)+(verList.search.pageSize*(verList.search.pageNo-1)) }}
                </template>
                <template v-slot:item.lastUpdateTime="{item}">
                  {{ item.lastUpdateTime || item.createTime }}
                </template>
                <template v-slot:item.queryFlag="{item}">
                  <span :class="{'red--text':(item.queryFlag=='1')}">
                    {{ item.queryFlag=='0'?'可质疑':(item.queryFlag=='1'?'不可质疑':'') }}
                  </span>
                </template>
                <template v-slot:item.statusFlag="{item}">
                  <span :class="{'red--text':(item.statusFlag=='1')}">
                    {{ item.statusFlag=='0'?'可用':(item.statusFlag=='1'?'禁用':'') }}
                  </span>
                </template>
                <template v-slot:item.active="{item}">
                  <v-icon :disabled="(item.statusFlag==='1') || (verList.statusFlag === '1')" color="primary" size="18" class=" mr-1" title="编辑" @click="verEdit(item)">mdi-square-edit-outline</v-icon>
                  <v-icon :disabled="(item.statusFlag==='1') || (verList.statusFlag === '1')" color="primary" size="18" class=" mr-1" title="设计" @click="drawer.title=item.reportName,drawer.reportId=item.reportId,drawer.reportVersionId=item.id,getForm('design'), exportLabel.reportId = item.reportId, exportLabel.reportVersionId = item.id">mdi-view-dashboard-outline</v-icon>
                  <v-icon
                    :disabled="verList.statusFlag==='1'"
                    :color="item.statusFlag==1?'primary':'error'"
                    size="18"
                    class=" mr-1"
                    :title="item.statusFlag==1?'恢复':'禁用'"
                    @click="operation($api.projectReportSetVersionStatus,'post',item.statusFlag==1?'恢复':'禁用',{id:item.id,statusFlag:item.statusFlag==1?'0':'1'})"
                  >
                    {{ item.statusFlag==1?'mdi-replay':'mdi-cancel' }}
                  </v-icon>

                  <v-icon
                    color="error"
                    size="20"
                    class=" mr-1"
                    title="删除"
                    @click="operation($api.projectReportDeleteVersion,'delete','删除',{id:item.id})"
                  >mdi-delete-outline</v-icon>

                  <v-icon color="primary" size="18" class=" mr-1" title="预览" @click="drawer.title=item.reportName,drawer.reportId=item.reportId,drawer.reportVersionId=item.id,getForm('see')">mdi-eye-outline</v-icon>
                  <v-icon color="primary" size="18" class=" mr-1" title="上传导出模版" @click="upAndDown.show = true, upAndDown.id = item.versionTemplateId, upAndDown.params = { ...upAndDown.params, reportId: item.reportId, versionId: item.id }">mdi-arrow-expand-vertical</v-icon>
                </template>
              </v-data-table>
              <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end">
                <pagination
                  v-model="verList.search.pageNo"
                  :size="verList.search.pageSize"
                  :total="verList.total"
                  @input="()=>{}"
                  @changePageSize="(e)=>{verList.search.pageSize=e,verList.search.pageNo=1,$forceUpdate()}"
                />
              </div>
            </div>

          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div class="max-width flex-y-center flex-justify-between">
            <div class="flex-1 flex-justify-end">
              <v-btn width="80" height="30" class depressed @click="verList.show = false,verList.data={}">关闭</v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
      <v-navigation-drawer
        v-if="drawer.show"
        v-model="drawer.show"
        temporary
        right
        stateless
        width="1100"
        fixed
      >
        <!-- @save="handleSave" -->
        <div class=" flex-column flex-justify-between" />
        <div class=" flex-0 flex-shrink-0 flex-justify-between flex-y-center py-3 px-5" style="border-bottom:1px solid #e1e1e1">
          <span class="text-h6">{{ drawer.title }}</span>
          <v-icon @click="drawer.show=false,drawer.data={},close()">mdi-close</v-icon>
        </div>
        <div class=" flex-1 overflow-hidden">
          <k-form-design
            v-if="drawer.type=='design'"
            ref="kfd"
            :toolbars="toolbars"
            :show-toolbars-text="true"
            :fields=" [ 'input', 'textarea', 'number', 'select', 'checkbox', 'radio', 'date', 'text', 'grid',
                        'H1', 'H2', 'H3', 'H4']"
            @save="handleSave"
            @handleExportLabel="handleExportLabel"
          />
          <!-- 'time', 'uploadFile' , 'switch', 'batch', 'divider', 'grid', -->
          <!-- <div  v-else-if="drawer.type=='see'">dsfsf</div> -->
          <div v-else-if="drawer.type=='see'" class=" max-height px-12 pa-5 overflow-auto">
            <k-form-build
              ref="kfb"
              :value="buildData"
            />
          </div>
        </div>
        <div />
      </v-navigation-drawer>

    </v-dialog>
    <!-- 版本列表 end -->

    <!-- 导入导出模版 start -->
    <v-dialog v-model="upAndDown.show" width="500px">
      <v-card class=" flex-column overflow-hidden">
        <v-card-title class="font-weight-bold flex-justify-between">
          <span>上传导出模版</span>
          <v-icon
            class="v-icon notranslate v-chip__close v-icon--link v-icon--right mdi mdi-close theme--light"
            @click="upAndDown.show = false"
          >mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class=" flex-1 px-0 overflow-auto">
          <div class="flex-align-start mt-6 ml-4 mr-4">
            <div class="flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>导入：</div>
            <div>
              <input
                :value="upAndDown.params.file"
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="文件导入"
                required
                type="file"
                @input="updateFile"
              />
              <div class="mt-2" @click="downLoadFile"><a href="JavaScript:void(0)">下载当前模版</a></div>
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div class="max-width flex-y-center flex-justify-between">
            <div class="flex-1 flex-justify-end">
              <v-btn dark small color="primary" title="导入" depressed min-width="80" height="30" class="mr-2" @click="upLoadFile">导入</v-btn>
              <v-btn width="80" height="30" class depressed @click="upAndDown.show = false,upAndDown.params.file = ''">关闭</v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 导入导出模本 end -->

  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      toolbars: ['save', 'preview', 'reset', 'export-label'],
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0,
      headers: [
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '报告名称', value: 'reportName' },
        { text: '首次提交间隔上限', value: 'submitIntervalLimit' },
        { text: '报告定稿间隔上限', value: 'tmfIntervalLimit' },
        { text: '报告审阅间隔上限', value: 'auditIntervalLimit' },
        { text: '状态', value: 'statusFlag' },

        // { text: "确认函间隔上限", value: "comfirmIntervalLimit" },
        // { text: "跟进函间隔上限", value: "followupIntervalLimit" },
        { text: '操作', value: 'active', sortable: false }
      ],
      list: [],
      listLoading: true,
      btnLoading: false,
      dialog: {
        show: false,
        type: '',
        data: {

        }
      },
      buildData: {},
      drawer: {
        show: false,
        data: {}
      },
      verList: {
        show: false,
        data: {},
        statusFlag: null, // 外面列表状态（是否禁用）
        search: {
          pageSize: 10,
          pageNo: 1
        },
        total: 0,
        headers: [
          { text: '序号', value: 'rowIndex', width: '50', sortable: false },
          { text: '版本名称', value: 'versionName', show: true },
          { text: '版本日期', value: 'versionDate', show: true },
          { text: '最后更新时间', value: 'lastUpdateTime', show: true },
          { text: '质疑', value: 'queryFlag', show: true },
          { text: '状态', value: 'statusFlag', show: true },
          { text: '操作', value: 'active', show: true }
        ],
        list: [],
        listLoading: false
      },
      verEditDialog: {
        show: false,
        data: {}
      },
      upAndDown: {
        id: '',
        show: false,
        params: {},
        data: []
      },
      exportLabel: {}
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 下载
    dowmLoad(res) {
      const _r = res
      const _this = this
      // const blob = new Blob([_res])
      const blob = new Blob([_r.data], { type: 'application/octet-stream' })
      const href = window.URL.createObjectURL(blob)
      try {
        const downloadElement = document.createElement('a')
        // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = (decodeURI(_r.headers['content-disposition'].slice(_r.headers['content-disposition'].indexOf('filename=') + 9))) // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
        _this.$forceUpdate()
        _this.$message.success('下载完成！')
        _this.upAndDown.show = false
      } catch (err) {
        _this.$message.error('当前没有上传模版')
      }
    },
    // 导出标签
    handleExportLabel() {
      this.$http.blobGet(this.$api.backendFormOutByReportId, { data: { ...this.exportLabel }}).then(res => {
        this.dowmLoad(res)
      }).catch(err => {
        console.log(err)
      })
    },
    async upLoadFile() {
      const form = new FormData()
      form.append('file', this.file)
      form.append('reportId', this.upAndDown.params.reportId)
      form.append('versionId', this.upAndDown.params.versionId)
      const rlt = await this.$http.post(this.$api.backendProjectReportUploadVersionTemplate, { data: form })
      if (rlt.success) {
        this.upAndDown.show = false
        this.projectReportVersionList()
      }
    },
    async downLoadFile() {
      this.$http.blobGet(this.$api.backendProjectReportVersionTemplateDownload, { data: { id: this.upAndDown.id }}).then(res => {
        this.dowmLoad(res)
      }).catch(err => {
        console.log(err)
      })
    },
    updateFile(event) {
      this.file = event.target.files[0]
    },
    initList() {
      this.search.pageNo = 1
      this.getList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.getList()
    },
    getList() {
      this.listLoading = true
      const search = { ...this.search.data }
      search.pageSize = this.search.pageSize
      search.pageNo = this.search.pageNo
      const param = new URLSearchParams()

      for (const i in search) {
        if (search[i]) param.append(i, search[i])
      }
      this.$http.get(this.$api.projectReportList, { data: param }).then(res => {
        this.list = res.result && res.result.records || []
        this.total = (res.result && res.result.total) || 0
        this.listLoading = false
      }).catch(() => {
        this.listLoading = false
      })
    },
    // 初始化获取版本列表
    initGetVersionList() {
      this.verList.search.pageNo = 1
      this.projectReportVersionList()
    },
    // 获取版本列表
    projectReportVersionList() {
      // reportId
      this.verList.listLoading = true
      const search = { ...this.verList.search.data }
      search.pageSize = this.verList.search.pageSize
      search.pageNo = this.verList.search.pageNo
      search.reportId = this.verList.reportId
      const param = new URLSearchParams()
      for (const i in search) {
        if (search[i]) param.append(i, search[i])
      }
      this.$http.get(this.$api.projectReportVersionList, { data: param }).then(res => {
        this.verList.list = res.result && res.result.records || []
        this.verList.total = (res.result && res.result.total) || 0
        this.verList.listLoading = false
      }).catch(() => {
        this.verList.listLoading = false
      })
    },
    // 操作  ($api.projectReportDelete,'delete','删除',{id:item.id})
    operation(api, method, title, params) {
      // if(confirm('是否确认'+title+'？')){
      //   let param = new URLSearchParams()
      //   for(let i in params){
      //     if(params[i]) param.append(i, params[i])
      //   }
      //   this.$http[method](api,{data:param}).then(res => {
      //       this.$message.success(res.message)
      //       this.initList()
      //   })
      // }
      this.$confirm({ text: '是否确认' + title + '？' }).then((vm) => {
        const param = new URLSearchParams()
        for (const i in params) {
          if (params[i]) param.append(i, params[i])
        }
        vm.loading = true
        this.$http[method](api, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          if (this.verList.show) {
            this.initGetVersionList()
          } else {
            this.initList()
          }
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    // 编辑弹窗
    showEdit(item) {
      this.dialog.show = true
      this.dialog.data = { ...item }
    },
    addShow() {
      this.dialog.data = {}
      this.dialog.show = true
    },
    delShow(id) {
      this.$http.delete(this.$api.userDeleteBatch, { ids: id }).then(res => {
        if (res.code === 0) {
          this.getList()
        }
      })
    },
    submitAdd() {
      let url = this.$api.projectReportAdd
      let method = 'post'
      if (this.dialog.data.id) {
        url = this.$api.projectReportEdit
        method = 'put'
      }
      this.btnLoading = true
      this.$http[method](url, { data: { ...this.dialog.data }}).then(res => {
        console.log(res)
        this.dialog.show = false
        this.btnLoading = false
        this.dialog.data = {}
        this.initList()
      }).catch(() => {
        this.btnLoading = false
      })
    },
    close() {
      this.drawer.data = {}
      setTimeout(() => {
        const _a = {
          list: [],
          config: {
            layout: 'vertical', // horizontal
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
            hideRequiredMark: false,
            customStyle: ''
          }
        }
        this.$refs.kfd && this.$refs.kfd.handleSetData(_a)

        this.$refs.kfd && this.$refs.kfd.close()
        this.drawer.show = false
      }, 200)
    },
    // formQueryByReportId
    getForm(type) {
      const param = new URLSearchParams()
      param.append('reportId', this.drawer.reportId)
      param.append('reportVersionId', this.drawer.reportVersionId)
      this.drawer.type = type
      this.drawer.show = true
      this.$http.get(this.$api.formQueryByReportId, { data: param }).then(res => {
        this.drawer.id = ''
        if (res.result) {
          this.drawer.id = res.result.id
          const _a = res.result.formSettings ? JSON.parse(res.result.formSettings) : { list: [],
            config: {
              layout: 'vertical', // horizontal
              labelCol: { span: 4 },
              wrapperCol: { span: 18 },
              hideRequiredMark: false,
              customStyle: ''
            }}
          setTimeout(() => {
            if (type === 'see') {
              this.buildData = _a
            } else {
              this.$refs.kfd.handleSetData(_a)
            }
          }, 200)
        } else {
          const _a = { list: [],
            config: {
              layout: 'vertical', // horizontal
              labelCol: { span: 4 },
              wrapperCol: { span: 18 },
              hideRequiredMark: false,
              customStyle: ''
            }}
          setTimeout(() => {
            if (type === 'see') {
              this.buildData = _a
            } else {
              this.$refs.kfd.handleSetData(_a)
            }
          }, 200)
        }
      })
    },
    // 保存 表单
    handleSave(e) {
      const _params = {
        formSettings: e,
        // id: this.drawer.id
        // reportId: this.drawer.reportId
        versionId: this.drawer.reportVersionId,
        reportId: this.verList.reportId
      }
      //  put formEdit this.$api.formSave
      this.$http.post(this.$api.formSave, { data: _params }).then(res => {
        this.$message.success(res.message)
        this.projectReportVersionList()
      })
    },
    // 添加版本 弹窗
    addVer() {
      this.verEditDialog.show = true
      this.verEditDialog.data = { reportId: this.verList.reportId }
    },
    // 编辑版本 弹窗
    verEdit(e) {
      this.verEditDialog.show = true
      this.verEditDialog.data = this.$deepClone(e)
    },
    // projectReportAddVersion
    // 确认提交版本
    submitVer() {
      let url = this.$api.projectReportAddVersion
      let method = 'post'
      if (this.verEditDialog.data.id) {
        url = this.$api.projectReportEditVersion
        method = 'put'
      }
      this.btnLoading = true
      this.$http[method](url, { data: { ...this.verEditDialog.data }}).then(res => {
        this.verEditDialog.show = false
        this.btnLoading = false
        this.verEditDialog.data = {}
        this.$message.success(res.message)
        this.projectReportVersionList()
      }).catch(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title{
    width:150px;
    color: #777;
}
</style>
